import React from 'react';
import Helmet from 'react-helmet';
import config from '../../data/SiteConfig';
import Drawer from '../components/Drawer/Drawer';
import Navigation from '../components/Navigation/Navigation';
import SiteWrapper from '../components/SiteWrapper/SiteWrapper';
import MainHeader from '../components/MainHeader/MainHeader';
import MainNav from '../components/MainNav/MainNav';
import BlogLogo from '../components/BlogLogo/BlogLogo';
import MenuButton from '../components/MenuButton/MenuButton';
import PageTitle from '../components/PageTitle/PageTitle';
import Footer from '../components/Footer/Footer';

class PageNotFoundPage extends React.Component {
  render() {
    return (
      <Drawer>
        <Helmet title={`Page not found | ${config.siteTitle}`} />

        {/* The blog navigation links */}
        <Navigation config={config} onClose={this.handleOnClose} />
        <SiteWrapper>
          {/* All the main content gets inserted here */}
          <div className="tag-template">
            {/* The big featured header */}
            <MainHeader className="tag-head">
              <MainNav>
                <BlogLogo />
                <MenuButton navigation={config.siteNavigation} onClick={this.handleOnClick} />
              </MainNav>
              <div className="vertical">
                <div className="main-header-content inner">
                  <PageTitle text="Page not found" />
                  {/* <PageDescription text="It looks like the page you're looking for can't be found." /> */}
                  <p>
                    Go back <a href={config.pathPrefix}>home</a>.
                  </p>
                </div>
              </div>
            </MainHeader>
          </div>
          {/* The tiny footer at the very bottom */}
          <Footer copyright={config.copyright} promoteGatsby={config.promoteGatsby} />
        </SiteWrapper>
      </Drawer>
    );
  }
}

export default PageNotFoundPage;
